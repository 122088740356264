import React from 'react';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Feature from '../Feature/Feature';
import styles from './FeatureRow.module.scss';

const FeatureRow = ({ content, hasBtn = true }) => {
  return (
    <ContentWrapper>
      <div className={styles.FeatureRow}>
        {content.map((feature) => {
          return (
            <Feature
              key={JSON.stringify(feature)}
              header={feature.header}
              description={feature.description || feature.subHeader}
              hasBtn={false}
              btnLabel={'Read More'}
              icon={feature.icon}
            />
          );
        })}
      </div>
    </ContentWrapper>
  );
};

export default FeatureRow;
