// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_Button__XMdsc {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  height: 45px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s ease-in-out;
}
.Button_Button__XMdsc.Button_disabled__DkgJ\\+ {
  cursor: not-allowed;
}
.Button_Button__XMdsc.Button_default__e3o5q {
  background-color: #015d8e;
  color: white;
}
.Button_Button__XMdsc.Button_default__e3o5q:hover {
  background-color: #0b4b6d;
}
.Button_Button__XMdsc.Button_default__e3o5q:hover .Button_icon__Zd50D {
  transform: translateX(5px);
}
.Button_Button__XMdsc.Button_secondary__sUGsj {
  background-color: #ffbc51;
  color: white;
}
.Button_Button__XMdsc.Button_secondary__sUGsj:hover {
  background-color: #d99221;
}
.Button_Button__XMdsc .Button_icon__Zd50D {
  transition: 0.3s ease-in-out;
}
.Button_Button__XMdsc .Button_icon__Zd50D svg {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,cCDY;EDEZ,YAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,4BAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,yBCjBc;EDkBd,YAAA;AAFJ;AAII;EAIE,yBCvBiB;ADkBvB;AAEM;EACE,0BAAA;AAAR;AAME;EACE,yBC/BY;EDgCZ,YAAA;AAJJ;AAMI;EACE,yBClCe;AD8BrB;AAQE;EACE,4BAAA;AANJ;AAOI;EACE,eAAA;AALN","sourcesContent":["@import '../../variables';\n\n.Button {\n  padding: 0 20px;\n  font-size: 16px;\n  font-weight: 600;\n  height: 45px;\n  color: $white-color;\n  border: none;\n  border-radius: 10px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  transition: 0.3s ease-in-out;\n\n  &.disabled {\n    cursor: not-allowed;\n  }\n\n  &.default {\n    background-color: $secondary-color;\n    color: white;\n\n    &:hover {\n      .icon {\n        transform: translateX(5px);\n      }\n      background-color: $secondary-dark-color;\n    }\n  }\n\n  &.secondary {\n    background-color: $primary-color;\n    color: white;\n\n    &:hover {\n      background-color: $primary-dark-color;\n    }\n  }\n\n  .icon {\n    transition: 0.3s ease-in-out;\n    svg {\n      font-size: 14px;\n    }\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `Button_Button__XMdsc`,
	"disabled": `Button_disabled__DkgJ+`,
	"default": `Button_default__e3o5q`,
	"icon": `Button_icon__Zd50D`,
	"secondary": `Button_secondary__sUGsj`
};
export default ___CSS_LOADER_EXPORT___;
