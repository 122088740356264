// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeatureRow_FeatureRow__n164p {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .FeatureRow_FeatureRow__n164p {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FeatureRow/FeatureRow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EANF;IAOI,sBAAA;IACA,SAAA;IACA,mBAAA;EAEF;AACF","sourcesContent":[".FeatureRow {\n  display: flex;\n  justify-content: space-between;\n  gap: 30px;\n  margin-bottom: 20px;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    gap: 15px;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FeatureRow": `FeatureRow_FeatureRow__n164p`
};
export default ___CSS_LOADER_EXPORT___;
