import React from 'react';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './Partners.module.scss';
import AHCCCS2 from '../../assets/images/partners/AHCCCS2.jpg';
import AZCH from '../../assets/images/partners/AZCH.jpg';
import FHW from '../../assets/images/partners/fallon-health-web.png';
import GA_PeachStateHP from '../../assets/images/partners/GA_PeachStateHP.png';
import GADCH3 from '../../assets/images/partners/GA-DCH3.jpg';
import husky_logo from '../../assets/images/partners/husky_logo.png';
import MassHealth from '../../assets/images/partners/MassHealth.png';
import NEHEN_Logo_Blue_vertical from '../../assets/images/partners/NEHEN_Logo_Blue_vertical.webp';
import NYDoH from '../../assets/images/partners/NY-DoH.png';
import PACHIP1 from '../../assets/images/partners/PA-CHIP1.png';
import PADHS from '../../assets/images/partners/PA-DHS.jpg';
import PaySpan from '../../assets/images/partners/PaySpan.png';
import Pennie from '../../assets/images/partners/Pennie.jpg';
import TexasStar from '../../assets/images/partners/TexasStar.webp';
import Trizetto from '../../assets/images/partners/Trizetto.webp';
import TXHHS from '../../assets/images/partners/TX - HHS.jpg';
import VACardinalCare from '../../assets/images/partners/VA-CardinalCare.png';
import VADMAS from '../../assets/images/partners/VA-DMAS.png';
import VAMES from '../../assets/images/partners/VA-MES.jpg';
import WAApple from '../../assets/images/partners/WA-Apple.png';
import WellSense from '../../assets/images/partners/WellSense.webp';
import Zelis from '../../assets/images/partners/Zelis.jpg';

const Partners = () => {
  return (
    <ContentWrapper>
      <div className={styles.Partners}>
        <h1 className={styles.Header}>Our Clearinghouse is Integrated With Major Payors</h1>
        <div className={styles.list_container}>
          <img src={AHCCCS2} alt="Feature Image" />
          <img src={AZCH} alt="Feature Image" />
          <img src={FHW} alt="Feature Image" />
          <img src={GA_PeachStateHP} alt="Feature Image" />
          <img src={GADCH3} alt="Feature Image" />
          <img src={husky_logo} alt="Feature Image" />
          <img src={MassHealth} alt="Feature Image" />
          <img src={NEHEN_Logo_Blue_vertical} alt="Feature Image" />
          <img src={NYDoH} alt="Feature Image" />
          <img src={PACHIP1} alt="Feature Image" />
          <img src={PADHS} alt="Feature Image" />
          <img src={PaySpan} alt="Feature Image" />
          <img src={Pennie} alt="Feature Image" />
          <img src={TexasStar} alt="Feature Image" />
          <img src={Trizetto} alt="Feature Image" />
          <img src={TXHHS} alt="Feature Image" />
          <img src={VACardinalCare} alt="Feature Image" />
          <img src={VADMAS} alt="Feature Image" />
          <img src={VAMES} alt="Feature Image" />
          <img src={WAApple} alt="Feature Image" />
          <img src={WellSense} alt="Feature Image" />
          <img src={Zelis} alt="Feature Image" />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Partners;
