import {
  CustomerServiceOutlined,
  MailOutlined,
  MedicineBoxTwoTone,
  PhoneOutlined,
  SecurityScanTwoTone,
  TrophyTwoTone,
  CheckOutlined,
  DashboardOutlined,
  BankFilled,
} from '@ant-design/icons';
import context from '../src/assets/images/illustrations/context.png';
import automation from '../src/assets/images/illustrations/automation.png';
import payouts from '../src/assets/images/illustrations/payouts.png';
import notifs from '../src/assets/images/illustrations/notifs.png';
import billers from '../src/assets/images/illustrations/billers.png';
import evv from '../src/assets/images/illustrations/evv.png';
import e2e from '../src/assets/images/illustrations/e2e.png';
import prior from '../src/assets/images/illustrations/prior.png';
import paid from '../src/assets/images/illustrations/paid.png';
import eligibility from '../src/assets/images/illustrations/eligibility.png';
import grow from '../src/assets/images/illustrations/grow.png';

export const navigation = [
  {
    header: 'Billing',
    baseLink: '/billing',
    baseIcon: <CheckOutlined />,
    tabs: [
      { linkTo: '#end-to-end', label: 'End-to-End' },
      { linkTo: '#denials-management', label: 'Denials Management' },
      { linkTo: '#prior-auth', label: 'Prior Auth' },
      { linkTo: '#eligibility', label: 'Eligibility' },
      { linkTo: '#credentialing-and-enrollment', label: 'Credentialing & Enrollment' },
    ],
  },
  {
    header: 'Eligibility',
    baseLink: '/eligibility',
    baseIcon: <CheckOutlined />,
    tabs: [
      { linkTo: '#maximize-payouts', label: 'Maximize Payouts' },
      { linkTo: '#notifications', label: 'Tracking and Notifications' },
    ],
  },
  {
    header: 'Our Platform',
    baseLink: '/platform',
    baseIcon: <DashboardOutlined />,
    tabs: [
      { linkTo: '#dashboard', label: 'Dashboard' },
      { linkTo: '#clearing-house', label: 'Clearing House' },
      { linkTo: '#billers', label: 'Billers & Institutions' },
    ],
  },
  {
    header: 'Company',
    baseLink: '/company',
    baseIcon: <BankFilled />,
    tabs: [
      { linkTo: '#about-us', label: 'About Us' },
      { linkTo: '#careers', label: 'Careers' },
    ],
  },
];

export const billingContent = [
  {
    type: 'end-to-end',
    header: 'End-to-end Service',
    subHeader:
      'Anise provides a full end-to-end billing process, starting with integrating with your EMR. We handle submission, status checks, and ERA management',
    image: e2e,
  },
  {
    type: 'denials-management',
    header: 'Get 100% Paid',
    subHeader:
      'As part of our service, we also handle any denials and aim to resolve these to get to 100% payment within 2 payment cycles',
    image: paid,
  },
  {
    type: 'prior-auth',
    header: 'Manage your Prior Auths',
    subHeader:
      'We leverage A.I. to onboard your Prior Authorizations and manage these by ensuring tracking through 90, 60, and 30 day cadences',
    image: prior,
  },
  {
    type: 'eligibility',
    header: 'Eligibility Before Service',
    subHeader:
      'Our platform provides daily eligibility verification checks to ensure eligibility issues are addressed before service is provided',
    image: eligibility,
  },
  {
    type: 'credentialing-and-enrollment',
    header: 'Grow your Practice',
    subHeader:
      'We support you with credentialing and enrollment services to help you grow your practice. Accept more payers!',
    image: grow,
  },
];

export const eligibilityContent = [
  {
    type: 'maximize-payouts',
    header: 'Maximize Payouts',
    subHeader:
      'Our platform verifies eligibility and insurance coverage information on an ongoing basis to ensure you provide and pay for service that will be compensated',
    image: payouts,
  },
  {
    type: 'notifications',
    header: 'Tracking and Notifications',
    subHeader:
      'We track Eligibility by providing daily notifications for ineligibility, coverage changes, and other potential issues',
    image: notifs,
  },
];

export const platformContent = [
  {
    header: 'Dashboards',
    description:
      'We provide dashboarding, reporting, and notifications to minimize operational overhead in your billing cycles',
  },
  {
    header: 'Automation',
    description:
      'Our platform allows you to leverage automation capabilities, including A.I. to manage your practice',
  },
  {
    header: 'Integrations',
    description:
      'We integrate with your EHRs and Payors to speed up processing, minimize errors, and maximize payouts',
  },
];

export const companyContent = [
  { header: 'About', description: 'Your company...' },
  { header: 'Careers', description: 'Careers...' },
];

export const platformDescription = [
  {
    type: 'clearing-house',
    label: '',
    header: 'Anise Clearing House',
    description:
      "As our customer, you get to enjoy our very own Anise Clearing House - with a direct connection to your Payers. Our Clearing House verifies the patient's insurance eligibility, submits the claims electronically, and tracks statuses. This helps to streamline the billing process, reduce denials, and speed up reimbursements for healthcare providers.",
    image: evv,
  },
  {
    type: 'billers',
    label: 'other clients',
    header: 'Institutions and Billers',
    description:
      'We provide other Clients with Clearing House capabilities, including 270/71s, 276/77s, 835/7s, etc',
    image: billers,
  },
];

export const homePageDescribers = [
  {
    header: 'Context Specific',
    description:
      'Our platform is program-centric, allowing for the very specific program nuances to be captured and monitored for efficient billing and practice management',
    icon: <SecurityScanTwoTone />,
    image: context,
  },
  {
    header: 'Integrations and Automation',
    description:
      'We leverage cutting-edge technology, including A.I., to automate the billing processes and integrate with other platforms to minimize errors and maximize payouts',
    icon: <MedicineBoxTwoTone />,
    image: automation,
  },
];

export const heroDescription = [
  {
    header: 'Visibility',
    description:
      'Track your full claims cycle through our dashboards across the full billing cycle, including Claims Submissions, Status Checks, Eligibility Changes, and Remittance Advice',
    icon: 'visibility-icon',
  },
  {
    header: 'Automation',
    description:
      'We automate your entire revenue cycle, including Eligibility Verification checks (daily) and Prior Authorization management to ensure you maximize your revenues for services provided.',
    icon: 'automation-icon',
  },
  {
    header: 'Integrations',
    description:
      'Our clearinghouse is integrated with all the major Payers, including Medicaid, Medicare, Private Insurance, MCOs, and SCOs, allowing us to support your census growth by accepting a variety of payers that serve your patients',
    icon: 'full-service',
  },
  {
    header: 'Compliant and Secure',
    description:
      'We prioritize the security and compliance (HIPAA) of our tech stack to protect sensitive patient information and ensure regulatory adherence.',
    icon: 'compliant-icon',
  },
];

export const aboutUsContent = [
  {
    header: 'Our Mission',
    description:
      'We aim to leverage the best technologies to simplify and accelerate billing for providers. Our smart billing platform reduces errors, maximizes payouts, and provides full visibility into your practices, allowing you to do what you do best ‐ providing care',
    icon: <TrophyTwoTone />,
  },
  {
    header: 'Who we are',
    description:
      'Anise brings together experts in medical billing, technology, and healthcare. We believe that improving medical billing can have a positive profound effect on the American Healthcare system.',
    icon: <TrophyTwoTone />,
  },
];

export const footerContent = [
  {
    header: 'Features',
    subTabs: [
      { label: 'Clearing House', isExternalLink: false, link: '/platform#clearing-house' },
      { label: 'Dashboard', isExternalLink: false, link: '/platform#dashboard' },
      { label: 'Automation', isExternalLink: false, link: '/platform#automation' },
      { label: 'Integration', isExternalLink: false, link: '/platform#integration' },
    ],
  },
  {
    header: 'company',
    subTabs: [
      { label: 'About Us', isExternalLink: false, link: '/company#about-us' },
      { label: 'Careers', isExternalLink: false, link: '/company#careers' },
    ],
  },
  {
    header: 'contact us',
    subTabs: [
      { label: 'sales@anise.health', isExternalLink: false, icon: <MailOutlined /> },
      { label: 'support@anise.health', isExternalLink: false, icon: <CustomerServiceOutlined /> },
      { label: '(617) 379 6172', isExternalLink: false, icon: <PhoneOutlined /> },
    ],
  },
];
