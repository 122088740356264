import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './Toast.module.scss';

const Toast = ({ message, type, onClose }) => {
  return (
    <div className={styles.toast}>
      {type === 'success' ? (
        <div className={styles.icon}>
          <CheckOutlined />
        </div>
      ) : (
        <div className={styles.errorIcon}>
          <CloseOutlined />
        </div>
      )}
      <div className={styles.body}>
        <span>{type === 'success' ? 'SUCCESS' : 'ERROR'}</span>
        <p>{message}</p>
      </div>
      <div className={styles.close} onClick={onClose}>
        <CloseOutlined />
      </div>
    </div>
  );
};

export default Toast;
