// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Feature_Feature__2sYqF {
  border: 2px solid #e3ebf2;
  border-radius: 15px;
  font-size: 14px;
  margin: 10px 0;
  padding: 20px;
  width: 100%;
  color: #212121;
}
.Feature_Feature__2sYqF p {
  line-height: 20px;
}
.Feature_Feature__2sYqF svg {
  font-size: 2.5rem;
}
.Feature_Feature__2sYqF a {
  font-weight: 600;
  color: #015d8e;
}
.Feature_Feature__2sYqF a svg {
  margin-left: 5px;
  font-size: 13px;
}
.Feature_Feature__2sYqF a:hover {
  color: #0b4b6d;
}
@media (max-width: 768px) {
  .Feature_Feature__2sYqF {
    margin: 0;
    width: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Feature/Feature.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,WAAA;EACA,cCFY;ADCd;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,gBAAA;EACA,cCjBc;ADclB;AAKI;EACE,gBAAA;EACA,eAAA;AAHN;AAMI;EACE,cCxBiB;ADoBvB;AAQE;EA/BF;IAgCI,SAAA;IACA,YAAA;EALF;AACF","sourcesContent":["@import '../../variables';\n\n.Feature {\n  border: 2px solid $card-border-color;\n  border-radius: 15px;\n  font-size: 14px;\n  margin: 10px 0;\n  padding: 20px;\n  width: 100%;\n  color: $black-color;\n\n  p {\n    line-height: 20px;\n  }\n\n  svg {\n    font-size: 2.5rem;\n  }\n\n  a {\n    font-weight: 600;\n    color: $secondary-color;\n\n    svg {\n      margin-left: 5px;\n      font-size: 13px;\n    }\n\n    &:hover {\n      color: $secondary-dark-color;\n    }\n  }\n\n  @media (max-width: 768px) {\n    margin: 0;\n    width: unset;\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Feature": `Feature_Feature__2sYqF`
};
export default ___CSS_LOADER_EXPORT___;
