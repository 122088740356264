// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Partners_Partners__ZingY {
  margin: 30px 0;
  border: 1px solid #e3ebf2;
  padding: 50px 0;
  border-radius: 30px;
}
.Partners_Partners__ZingY .Partners_Header__bY9KO {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-align: center;
}
.Partners_Partners__ZingY .Partners_list_container__Px7Ec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.Partners_Partners__ZingY .Partners_list_container__Px7Ec img {
  width: auto;
  height: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.Partners_Partners__ZingY .Partners_list_container__Px7Ec img:hover {
  scale: 1.3;
}
@media (max-width: 768px) {
  .Partners_Partners__ZingY {
    margin: 0;
    border: none;
  }
  .Partners_Partners__ZingY .Partners_list_container__Px7Ec {
    flex-wrap: wrap;
  }
  .Partners_Partners__ZingY .Partners_list_container__Px7Ec img {
    height: 40px;
    width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Partners/Partners.module.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;AADF;AAGE;EACE,SAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,4BAAA;AAFN;AAIM;EACE,UAAA;AAFR;AAOE;EAhCF;IAiCI,SAAA;IACA,YAAA;EAJF;EAME;IACE,eAAA;EAJJ;EAMI;IACE,YAAA;IACA,WAAA;EAJN;AACF","sourcesContent":["@import '../../variables';\n\n.Partners {\n  margin: 30px 0;\n  border: 1px solid $card-border-color;\n  padding: 50px 0;\n  border-radius: 30px;\n\n  .Header {\n    margin: 0;\n    font-size: 1.5rem;\n    margin-bottom: 30px;\n    text-align: center;\n  }\n\n  .list_container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 2rem;\n    flex-wrap: wrap;\n\n    img {\n      width: auto;\n      height: 40px;\n      cursor: pointer;\n      transition: 0.3s ease-in-out;\n\n      &:hover {\n        scale: 1.3;\n      }\n    }\n  }\n\n  @media (max-width: 768px) {\n    margin: 0;\n    border: none;\n\n    .list_container {\n      flex-wrap: wrap;\n\n      img {\n        height: 40px;\n        width: auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Partners": `Partners_Partners__ZingY`,
	"Header": `Partners_Header__bY9KO`,
	"list_container": `Partners_list_container__Px7Ec`
};
export default ___CSS_LOADER_EXPORT___;
