// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentWrapper_ContentWrapper__5L4t3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ContentWrapper_ContentWrapper__5L4t3.ContentWrapper_bg_patterns__T2hPW {
  background-color: #ffbc51;
}
.ContentWrapper_ContentWrapper__5L4t3 > div {
  max-width: 1300px;
  align-self: center;
  width: 100%;
  height: 100%;
}
@media (max-width: 768px) {
  .ContentWrapper_ContentWrapper__5L4t3 {
    flex-direction: column;
    padding: 0 10px;
  }
  .ContentWrapper_ContentWrapper__5L4t3 > div {
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ContentWrapper/ContentWrapper.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,yBCNY;ADKhB;AAME;EACE,iBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAJJ;AAOE;EAlBF;IAmBI,sBAAA;IACA,eAAA;EAJF;EAME;IACE,aAAA;EAJJ;AACF","sourcesContent":["@import '../../variables';\n\n.ContentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.bg_patterns {\n    background-color: $primary-color;\n    // background-color: rgba(249, 168, 38, 0.2) !important;\n    // background-image: url('../../assets/images/background-patterns.svg');\n  }\n\n  > div {\n    max-width: 1300px;\n    align-self: center;\n    width: 100%;\n    height: 100%;\n  }\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    padding: 0 10px;\n\n    > div {\n      padding: 20px;\n    }\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentWrapper": `ContentWrapper_ContentWrapper__5L4t3`,
	"bg_patterns": `ContentWrapper_bg_patterns__T2hPW`
};
export default ___CSS_LOADER_EXPORT___;
