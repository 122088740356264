import { ArrowUpOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './GoTopBtn.module.scss';

const GoTopBtn = ({ onClick }) => {
  return (
    <div className={styles.GoTopBtn} onClick={onClick}>
      <div className={styles.btn}>
        <span className={styles.text}>Back to top</span>
        <ArrowUpOutlined />
      </div>
    </div>
  );
};

export default GoTopBtn;
