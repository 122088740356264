import styles from './Input.module.scss';

const TextAreaFormInput = ({
  hasLabel = true,
  type,
  placeholder,
  label,
  required,
  name,
  register,
  error,
  valueAsNumber,
}) => (
  <div className={styles.Input}>
    {hasLabel && (
      <label htmlFor={name}>
        {label} <span style={{ color: 'red' }}>{required && '*'}</span>
      </label>
    )}
    <div className={styles.InputField}>
      <textarea autoComplete='off' rows={5} type={type} placeholder={placeholder} {...register(name, { valueAsNumber })} />
      {error && <span className={styles.error}>{error.message}</span>}
    </div>
  </div>
);
export default TextAreaFormInput;
