import { ArrowRightOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { startTransition } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { footerContent } from '../../constants';
import Button from '../Button/Button';
import FormInput from '../Input/Input';
import styles from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(
      z.object({
        email: z.string().email('Invalid email address'),
      })
    ),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    console.log(data);

    setTimeout(() => {
      reset();
    }, 2000);
  };

  const handleNavigation = (path) => {
    startTransition(() => {
      navigate(path);
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    });
  };

  const FoooterSection = ({ item }) => {
    return (
      <div className={styles.link_side}>
        <h2 className={styles.footer_header}>{item.header}</h2>
        <ul>
          {item?.subTabs.map((link, index) => {
            return (
              <li key={index} onClick={() => (link.link ? handleNavigation(link.link) : null)}>
                {link.icon && <div className={styles.front_link_icon}>{link.icon}</div>}
                {link.label}
                {link.isExternalLink && (
                  <div className={styles.link_icon}>
                    <ArrowRightOutlined />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <footer className={styles.Footer}>
      <div className={styles.container}>
        <div className={styles.logo_side}>
          <div className={styles.logo} />
          <span>Smart Billing Platform for Medical Providers</span>
        </div>
        {footerContent.map((item, index) => {
          return <FoooterSection key={index} item={item} />;
        })}
        <div className={styles.form_side}>
          <h2 className={styles.footer_header}>Subscribe</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="text"
              hasLabel={false}
              required
              placeholder={'example@mail.com'}
              name={'email'}
              register={register}
              error={errors?.['email']}
            />
            <Button type="submit" variant={'secondary'} label="Subscribe" />
          </form>
        </div>
      </div>
      <div className={styles.footer_owner}>
        <span>© {year} - Anise Technologies Inc.</span>
      </div>
    </footer>
  );
};

export default Footer;
