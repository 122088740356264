import React from 'react';
import Button from '../Button/Button';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './Describer.module.scss';

const Describer = ({
  type = 'LEFT',
  label,
  header,
  description,
  hasBtn,
  btnLabel,
  icon,
  id,
  hasBGPatterns,
  image,
  onClickBtn,
}) => {
  const describerClass = `${styles.Describer} ${styles[type]}`;

  return (
    <ContentWrapper id={id} hasBGPatterns={hasBGPatterns}>
      <div className={describerClass}>
        <div className={styles.bg} />
        <div className={styles.illustration}>
          <img
            src={image ? image : 'https://readme.com/static/img/home/owl-developer-hub.png'}
            alt="Hero Illustration"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.icon}>{icon}</div>
          <div>{label && <span className={styles.labelHeader}>{label}</span>}</div>
          <h3 className={styles.heroHeader}>{header}</h3>
          <p className={styles.heroSubtext}>{description}</p>
          {hasBtn && (
            <Button
              label={btnLabel}
              onClick={onClickBtn}
              variant={hasBGPatterns ? 'default' : 'secondary'}
            />
          )}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Describer;
