// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoTopBtn_GoTopBtn__SCRQZ {
  position: fixed;
  z-index: 99;
  bottom: 24px;
  right: 24px;
  background-color: #015d8e;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px;
}
.GoTopBtn_GoTopBtn__SCRQZ .GoTopBtn_btn__sYPo8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  transition: all 500ms ease 0s;
  transform: translateY(2px);
}
.GoTopBtn_GoTopBtn__SCRQZ .GoTopBtn_btn__sYPo8 .GoTopBtn_text__JwV0S {
  display: none;
  font-size: 14px;
}
.GoTopBtn_GoTopBtn__SCRQZ:hover {
  padding: 0 15px;
  background-color: #0b4b6d;
}
.GoTopBtn_GoTopBtn__SCRQZ:hover .GoTopBtn_btn__sYPo8 {
  width: unset;
}
.GoTopBtn_GoTopBtn__SCRQZ:hover .GoTopBtn_btn__sYPo8 .GoTopBtn_text__JwV0S {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/GoTopBtn/GoTopBtn.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,yBCHgB;EDIhB,cCFY;EDGZ,mBAAA;EACA,eAAA;EACA,2CAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,6BAAA;EACA,0BAAA;AADJ;AAGI;EACE,aAAA;EACA,eAAA;AADN;AAKE;EACE,eAAA;EACA,yBC5BmB;ADyBvB;AAII;EACE,YAAA;AAFN;AAGM;EACE,cAAA;AADR","sourcesContent":["@import '../../variables';\n\n.GoTopBtn {\n  position: fixed;\n  z-index: 99;\n  bottom: 24px;\n  right: 24px;\n  background-color: $secondary-color;\n  color: $white-color;\n  border-radius: 30px;\n  cursor: pointer;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 8px;\n\n  .btn {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 12px;\n    width: 48px;\n    height: 48px;\n    border-radius: 24px;\n    transition: all 500ms ease 0s;\n    transform: translateY(2px);\n\n    .text {\n      display: none;\n      font-size: 14px;\n    }\n  }\n\n  &:hover {\n    padding: 0 15px;\n    background-color: $secondary-dark-color;\n    .btn {\n      width: unset;\n      .text {\n        display: block;\n      }\n    }\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GoTopBtn": `GoTopBtn_GoTopBtn__SCRQZ`,
	"btn": `GoTopBtn_btn__sYPo8`,
	"text": `GoTopBtn_text__JwV0S`
};
export default ___CSS_LOADER_EXPORT___;
