// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/russisch-sans/RussischSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'RussischSans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'RussischSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,wGAAgJ;IAChJ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;EACE,SAAS;EACT,2BAA2B;EAC3B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@font-face {\n    font-family: 'RussischSans';\n    src: url('./assets/fonts/russisch-sans/RussischSans-Medium.ttf'), url('./assets/fonts/russisch-sans/RussischSans-Medium.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\nbody {\n  margin: 0;\n  font-family: 'RussischSans';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
