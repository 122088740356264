import classNames from 'classnames';
import React, { useEffect } from 'react';
import styles from './ContentWrapper.module.scss';
import ScrollReveal from 'scrollreveal';

const ContentWrapper = ({ children, id, hasReveal = true, hasBGPatterns = false }) => {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: 'bottom',
      distance: '50px',
      duration: 1000,
      delay: 200,
      reset: false,
    });

    sr.reveal('.reveal', { interval: 200 });
  }, []);

  const classes = classNames(
    styles['ContentWrapper'],
    'ContentWrapper',
    hasReveal && 'reveal',
    hasBGPatterns && styles['bg_patterns']
  );
  return (
    <section className={classes} id={id}>
      <div>{children}</div>
    </section>
  );
};

export default ContentWrapper;
