import React, { useState } from 'react';
import Button from '../Button/Button';
import styles from './Form.module.scss';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import FormInput from '../Input/Input';
import TextAreaFormInput from '../Input/TextArea';
import Toast from '../Toast/Toast';
import { ReCAPTCHA } from 'react-google-recaptcha';

// Define the Zod schema
const FormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  company: z.string().min(1, 'Company is required'),
  email: z.string().email('Invalid email address'),
  phone_number: z
    .string()
    .min(1, 'Phone number is required')
    .refine((value) => /^\+?[1-9]\d{1,14}$/.test(value), {
      message: 'Invalid phone number',
    }),
  message: z
    .string()
    .min(1, 'Message is required')
    .refine((value) => value.trim().length >= 10, {
      message: 'Message must be at least 10 characters long',
    }),
});

// Define form fields
const FormFields = [
  { name: 'name', label: 'Name', type: 'text' },
  { name: 'company', label: 'Company', type: 'text' },
  { name: 'email', label: 'Email Address', type: 'email' },
  { name: 'phone_number', label: 'Phone Number', type: 'tel' },
  { name: 'message', label: 'Message', type: 'textarea' },
];


// Error handling

const DemoForm = () => {
  const [toast, setToast] = useState({ message: '', type: '', visible: false });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    formState: { errors, isValid }
  } = useForm({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  });

  const handleToastClose = () => {
    setToast({ ...toast, visible: false });
  };

  const method = "post";
  const demoRequestURL = "https://app.anise.health/core/demo-request"

  const recaptchaPublicKey = "6LdrpqsnAAAAAOTgg9YTlKhfG70SbPKtIqxFpzQm";

  return (
    <form className={styles.Form} method={method} action={demoRequestURL}>
      {toast.visible && (
        <Toast message={toast.message} type={toast.type} onClose={handleToastClose} />
      )}
      {FormFields.map((field) => (
        field.type == "textarea"?
        <TextAreaFormInput
        key={field.name}
          type="text"
          label={field.label}
          required={field.required}
          placeholder={field.label}
          name={field.name}
          register={register}
          error={errors[field.name]}
         />:
        <FormInput
          key={field.name}
          type="text"
          label={field.label}
          required={field.required}
          placeholder={field.label}
          name={field.name}
          register={register}
          error={errors[field.name]}
        />
      ))}

      <div className={styles.Button}>
        <Button
          label={isSubmitting ? 'Submitting...' : 'Submit'}
          type="submit"
          disabled={isSubmitting}
        />
      </div>

      <ReCAPTCHA sitekey={recaptchaPublicKey} />

      {errors.submit && <p className={styles.ErrorMessage}>{errors.submit}</p>}
    </form>
  );
};

export default DemoForm;