import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './Testimonial.module.scss';

const Testimonial = () => {
  const testimonials = [
    {
      id: 1,
      text: 'It has been amazing having our billing taken care of end-to-end. Automated eligibility verification daily, tracking details such as Prior Auths, MLOAs, and claims submissions, and full denials management for multiple Payors. ',
      name: 'Home Care Provider',
      role: 'CEO',
    },
    {
      id: 2,
      text: 'The visibility has been a game changer for us! The reports we receive on billing, payments, and eligibility changes have put us back in control of our revenue cycle. Even we were surprised by how dependent we were on manual billing processes',
      name: 'Home Care Provider',
      role: 'Administrator',
    },
    {
      id: 3,
      text: 'We have definitely grown faster since we onboarded on Anise. Operationally, our team can now focus on growing, providing care, and other initiatives.',
      name: 'Home Care Provider',
      role: 'COO',
    },
    {
      id: 4,
      text: 'We have definitely grown faster since we onboarded on Anise. Integrating with your platform ensures we don’t have to spend time moving over data for billing.',
      name: 'Skilled Nursing Provider',
      role: 'Head of Billing',
    },
    {
      id: 5,
      text: 'Being able to bill automatically, as an Independent Nurse, has significantly reduced the time I have to spend on figuring out how to get paid by MassHealth.',
      name: 'Independent Nurse',
      role: '',
    },
  ];

  const ArrowBtn = ({ type, onClick }) => {
    return (
      <div className={styles.arrowBtn} onClick={onClick}>
        {type === 'LEFT' && <ArrowLeftOutlined />}
        {type === 'RIGHT' && <ArrowRightOutlined />}
      </div>
    );
  };

  const [currentPage, setCurrentPage] = useState(0);

  const handleNext = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % Math.ceil(testimonials.length / 3));
  };

  const handlePrev = () => {
    setCurrentPage(
      (prevPage) =>
        (prevPage - 1 + Math.ceil(testimonials.length / 3)) % Math.ceil(testimonials.length / 3)
    );
  };

  return (
    <ContentWrapper>
      <div className={styles.Testimonial}>
        <div className={styles.cardContainer}>
          {testimonials.slice(currentPage * 3, (currentPage + 1) * 3).map((item, index) => {
            return (
              <div className={styles.card} key={item.id}>
                <p>{item.text}</p>
                <div className={styles.cardFooterContent}>
                  <h4>{item.name}</h4>
                  <span>{item.role}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.Footer}>
          <div className={styles.bulletContainer}>
            {Array.from({ length: Math.ceil(testimonials.length / 3) }).map((_, index) => (
              <div
                key={index}
                className={`${styles.bullet} ${currentPage === index ? styles.active : ''}`}
              />
            ))}
          </div>
          <div className={styles.arrowContainer}>
            <ArrowBtn type="LEFT" onClick={handlePrev} />
            <ArrowBtn type="RIGHT" onClick={handleNext} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Testimonial;
