import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Describer from '../components/Describer/Describer';
import FeatureRow from '../components/FeatureRow/FeatureRow';
import GoTopBtn from '../components/GoTopBtn/GoTopBtn';
import Hero from '../components/Hero/Hero';
import Partners from '../components/Partners/Partners';
import Testimonial from '../components/Testimonial/Testimonial';
import { heroDescription, homePageDescribers } from '../constants';
import { handleExternalNavigation } from '../utils';

const Home = () => {
  const [showGoTop, setShowGoTop] = useState(false);

  const navigate = useNavigate();

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 50);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
  }, []);

  return (
    <>
      {showGoTop && <GoTopBtn onClick={handleScrollUp} />}
      <>
        <Hero
          type={'homepage'}
          header={'Smart Billing Platform for Medical Providers.'}
          subHeader={
            'Fast, efficient, automated billing that eliminates errors and maximizes payouts.'
          }
          hasBtn
          btnLabel={'Book a Demo'}
          handleButtonClick={() => navigate('/request-demo')}
        />
        <FeatureRow content={heroDescription} />
        <Partners />
        {homePageDescribers.map((_, index) => {
          return (
            <Describer
              key={index}
              type={index % 2 === 1 ? 'RIGHT' : 'LEFT'}
              header={_.header}
              hasBGPatterns={index % 2 === 0}
              label={_.label}
              description={_.description}
              hasBtn
              btnLabel={'Get Started'}
              icon={_.icon}
              image={_.image}
              onClickBtn={() => handleExternalNavigation('https://app.anise.health')}
            />
          );
        })}
        <Testimonial />
      </>
    </>
  );
};

export default Home;
