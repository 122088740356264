import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { Route, Routes } from 'react-router-dom';
import Home from './sections/Home';
import NotFound from './sections/NotFound';
import { navigation } from './constants';
import Billing from './sections/Billing';
import Eligibility from './sections/Eligibility';
import Platform from './sections/Platform';
import Demo from './sections/Demo';
import Company from './sections/Company';

function App() {
  const renderRoutes = (baseLink, Component, tabs) => {
    return tabs.map((tab) => (
      <Route key={tab.linkTo} path={baseLink} element={<Component type={tab.linkTo} />} />
    ));
  };

  return (
    <>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        {navigation.map((route) => {
          switch (route.baseLink) {
            case '/billing':
              return renderRoutes(route.baseLink, Billing, route.tabs);
            case '/eligibility':
              return renderRoutes(route.baseLink, Eligibility, route.tabs);
            case '/platform':
              return renderRoutes(route.baseLink, Platform, route.tabs);
            case '/company':
              return renderRoutes(route.baseLink, Company, route.tabs);
            default:
              return null;
          }
        })}
        <Route path="/billing" element={<Home />} />
        <Route path="/request-demo" element={<Demo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
