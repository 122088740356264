import React from 'react';
import Describer from '../components/Describer/Describer';
import notfound from '../assets/images/illustrations/notfound.png';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Describer
      type={'RIGHT'}
      header={'Oops! Page Not Found'}
      hasBGPatterns={false}
      label={'404!'}
      description={
        "Sorry, we couldn't find the page you were looking for. It might have been moved or deleted, or perhaps you mistyped the URL."
      }
      hasBtn
      btnLabel={'Go to Home'}
      image={notfound}
      onClickBtn={() => navigate('/')}
    />
  );
};

export default NotFound;
