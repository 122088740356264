import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Describer from '../components/Describer/Describer';
import Hero from '../components/Hero/Hero';
import { billingContent } from '../constants';

const Billing = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <>
      <Hero
        id={'type'}
        type={'billing'}
        header={'Streamline Your Revenue Cycle With Anise'}
        subHeader={'Automate your entire billing cycle as a function of growth'}
        hasBtn={false}
      />
      {billingContent.map((section, index) => {
        return (
          <Describer
            key={index}
            header={section.header}
            description={section.subHeader}
            hasBtn={false}
            hasBGPatterns={index % 2 === 1}
            type={index % 2 !== 0 ? 'RIGHT' : 'LEFT'}
            id={section.type}
            image={section.image}
          />
        );
      })}
    </>
  );
};

export default Billing;
